import { dapis } from '@api3/dapi-management';
import styled from 'styled-components';
import tokens from 'design-tokens';
import { breakpoints } from '../styles/theme';
import { ScrollToTopButton } from '../custom/ScrollToTop';
import groupBy from 'lodash/groupBy';
import { DApiGroup } from './DApiGroup';
import { useCurrentChain } from '../hooks/use-current-chain';
import { fonts } from '../styles/fonts';

const groupedDapis = groupBy(dapis, 'metadata.category');

export const Welcome = () => {
    const chainName = useCurrentChain();

    return (
        <StyledWelcome>
            <div className="page-header">
                <h1>dAPI Catalog</h1>
                <span>{dapis.filter((dapi) => dapi.stage === 'active').length} Data feeds available for purchase</span>
            </div>
            <div className="page-content">
                {Object.keys(groupedDapis)
                    .sort()
                    .map((key) => (
                        <DApiGroup key={key} dapis={groupedDapis[key]} groupName={key} chainName={chainName} />
                    ))}
                <ScrollToTopButton />
            </div>
        </StyledWelcome>
    );
};

const StyledWelcome = styled('div')`
    display: flex;
    flex-direction: column;
    gap: ${tokens.core.spacing[300]}px;
    width: 100%;
    max-width: 814px;
    padding: ${tokens.core.spacing[250]}px ${tokens.core.spacing[100]}px;

    .page-header {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: ${tokens.core.spacing[50]}px;

        h1 {
            ${fonts.heading[8]};
            color: var(--color-base-light);
        }

        span {
            ${fonts.body[8]};
            color: var(--color-gray-200);
        }
    }

    .page-content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    @media (min-width: ${breakpoints.m}px) {
        gap: ${tokens.core.spacing[400]}px;

        .page-header {
            gap: ${tokens.core.spacing[150]}px;

            h1 {
                ${fonts.heading[3]};
            }

            span {
                ${fonts.subtitle[6]};
            }
        }
    }
`;
