import { List } from '@chakra-ui/react';
import tokens from 'design-tokens';
import styled from 'styled-components';
import { DApiRow } from './DApiRow';
import { DapiType } from '../types';
import { CaretDown } from '../custom/CaretDown';
import { breakpoints } from '../styles/theme';
import { fonts } from '../styles/fonts';

interface Props {
    dapis: DapiType[];
    groupName: string;
    chainName: string;
}

export const DApiGroup = ({ dapis, groupName, chainName }: Props) => {
    const listedDapis = dapis.filter((dapi) => dapi.stage === 'active');

    if (listedDapis.length === 0) {
        return null;
    }

    return (
        <Container>
            <summary>
                <div className="group-name">
                    <h3>{groupName}</h3>
                    <span>({listedDapis.length})</span>
                </div>
                <CaretDown />
            </summary>
            <List className="group-list">
                {listedDapis
                    .filter((dapi) => dapi.stage === 'active')
                    .map((dapi, index) => (
                        <DApiRow key={index} dapi={dapi} index={index} chainName={chainName} />
                    ))}
            </List>
        </Container>
    );
};

const Container = styled('details')`
    width: 100%;
    border-bottom: 1px solid var(--color-dark-blue-400);

    summary {
        list-style: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ${tokens.core.spacing[100]}px;

        .group-name {
            display: flex;
            align-items: center;
            gap: ${tokens.core.spacing[50]}px;

            > h3 {
                ${fonts.subtitle[11]};
                color: var(--color-base-light);
            }

            > span {
                ${fonts.body[8]};
                color: var(--color-dark-blue-25);
                transform: translateY(1px);
            }
        }

        svg {
            color: var(--color-base-light);
            min-width: 24px;
        }

        .group-name > span,
        svg {
            transition: all var(--default-transition);
        }
    }

    &[open] {
        padding-bottom: ${tokens.core.spacing[250]}px;
        margin-bottom: ${tokens.core.spacing[100]}px;

        summary {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    &:hover {
        summary {
            .group-name {
                > span {
                    color: var(--color-base-light);
                }
            }
        }
    }

    .group-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: var(--color-base-blue-ui);
        padding: ${tokens.core.spacing[50]}px 0;
        border-radius: 12px;
    }

    @media (min-width: ${breakpoints.m}px) {
        summary {
            justify-content: flex-start;
            gap: ${tokens.core.spacing[150]}px;
            padding: 26px ${tokens.core.spacing[100]}px;

            .group-name {
                > h3 {
                    ${fonts.subtitle[5]};
                }

                > span {
                    ${fonts.subtitle[12]};
                    transform: translateY(2px);
                }
            }
        }

        &[open] {
            padding-bottom: 56px;
            margin-bottom: ${tokens.core.spacing[150]}px;

            summary {
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        .group-list {
            padding: ${tokens.core.spacing[50]}px ${tokens.core.spacing[150]}px;
        }
    }
`;
