import Logos from '../custom/Logos';
import styled from 'styled-components';
import tokens from 'design-tokens';
import { breakpoints } from '../styles/theme';
import { DapiType } from '../types';
import { MARKET_URL } from '../constants';
import { fonts } from '../styles/fonts';

interface Props {
    dapi: DapiType;
    index: number;
    chainName: string;
}

const getMarketUrl = (chainName: string, dapiName: string) => `${MARKET_URL}/${chainName}?search=${dapiName}`;

export const DApiRow = ({ dapi, index, chainName }: Props) => {
    const dapiNameParts = dapi.name.split('/');

    return (
        <StyledRow key={index}>
            <a href={getMarketUrl(chainName, dapi.name)} target="_blank">
                <Logos dapiName={dapi.name} />

                <div className="dapi-name">
                    {dapiNameParts[0]}
                    <span className="dapi-name-slash">/</span>
                    {dapiNameParts[1]}
                </div>
            </a>
        </StyledRow>
    );
};

const StyledRow = styled.li`
    list-style: none;
    height: 80px;

    > a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        gap: ${tokens.core.spacing[100]}px;
        cursor: pointer;
        border-bottom: 1px solid var(--color-dark-blue-800);
        padding: ${tokens.core.spacing[100]}px;
        padding-bottom: ${tokens.core.spacing[150]}px;

        &,
        * {
            transition: all var(--default-transition);
        }

        .dapi-name {
            ${fonts.body[9]};
            color: var(--color-gray-200);
            text-align: left;

            .dapi-name-slash {
                color: var(--color-gray-600);
            }
        }

        &:hover {
            background-color: var(--color-dark-blue-800);

            .dapi-name {
                color: var(--color-dark-blue-25);

                .dapi-name-slash {
                    color: var(--color-dark-blue-100);
                }
            }
        }

        &:active {
            .dapi-name {
                color: var(--color-dark-blue-300);

                .dapi-name-slash {
                    color: var(--color-dark-blue-600);
                }
            }
        }
    }

    &:last-child > a {
        border-bottom: none;
    }

    @media (min-width: ${breakpoints.m}px) {
        height: 88px;

        > a {
            gap: ${tokens.core.spacing[150]}px;
            padding: ${tokens.core.spacing[150]}px ${tokens.core.spacing[100]}px;

            .dapi-name {
                ${fonts.body[3]};
            }
        }
    }
`;
