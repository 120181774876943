import styled from 'styled-components';
import { SymbolLogo } from '@api3/logos';
import { Skeleton } from '@chakra-ui/react';
import { breakpoints } from '../styles/theme';

interface Props {
    dapiName?: string;
}

const DatafeedLogos = (props: Props) => {
    const { dapiName } = props;

    const logos = dapiName?.split('/');

    if (!logos || logos.length === 0) {
        return (
            <Container aria-hidden>
                <Skeleton className="feed" />
                <Skeleton className="feed" />
            </Container>
        );
    }

    return (
        <Container aria-hidden>
            <img className="feed" src={SymbolLogo(logos[0])} alt={`${logos[0]} logo`} />
            <img className="feed" src={SymbolLogo(logos[1])} alt={`${logos[1]} logo`} />
        </Container>
    );
};

const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-size: border-box;

    .feed {
        z-index: 3;
        position: relative;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;

        &:nth-child(2) {
            z-index: 2;
            margin-left: -4px;
        }
    }

    @media (min-width: ${breakpoints.m}px) {
        .feed {
            width: 42px;
            height: 42px;
            min-width: 42px;
            min-height: 42px;
        }
    }
`;

export default DatafeedLogos;
