import { css } from '@chakra-ui/react';

export const fonts = {
    body: {
        2: css({
            fontFamily: 'Karla',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '150%' /* 30px */,
        }),
        3: css({
            fontFamily: 'Karla',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%' /* 30px */,
        }),
        8: css({
            fontFamily: 'Karla',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '140%' /* 22.4px */,
        }),
        9: css({
            fontFamily: 'Karla',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%' /* 22.4px */,
        }),
    },
    heading: {
        3: css({
            fontFamily: 'Poppins',
            fontSize: '48px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '110%' /* 52.8px */,
            letterSpacing: '1px',
        }),
        8: css({
            fontFamily: 'Poppins',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '130%' /* 26px */,
            letterSpacing: '0.5px',
        }),
    },
    subtitle: {
        5: css({
            fontFamily: 'Karla',
            fontSize: '22px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '130%' /* 28.6px */,
            letterSpacing: '-0.44px',
        }),
        6: css({
            fontFamily: 'Karla',
            fontSize: '22px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '130%' /* 28.6px */,
            letterSpacing: '-0.44px',
        }),
        11: css({
            fontFamily: 'Karla',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '140%' /* 25.2px */,
            letterSpacing: '-0.36px',
        }),
        12: css({
            fontFamily: 'Karla',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '140%' /* 25.2px */,
            letterSpacing: '-0.36px',
        }),
    },
};
